<template>
  <m-page-header title="搜索历史" />
  <div class="view">
    <div class="serach_input">
      <el-select v-model="prams.cache_type" placeholder="请选择缓存类型">
        <el-option label="缓存" value="1"></el-option>
        <el-option label="接口" value="0"></el-option>
      </el-select>
      <el-select v-model="prams.client_type" placeholder="客户端类型">
        <el-option label="全部" value="all"></el-option>
        <el-option label="PC" value="pc"></el-option>
        <el-option label="WAP" value="wap"></el-option>
      </el-select>
      <el-date-picker
        v-model="prams.time_range"
        type="daterange"
        range-separator="至"
        start-placeholder="搜索开始日期"
        end-placeholder="搜索结束日期"
        style="margin-left: 10px"
        @change="timeChange"
      >
      </el-date-picker>
      <el-input
        placeholder="请输入用户邮箱"
        v-model="prams.email"
        style="width: 240px; margin-left: 10px"
      >
        <template #append>
          <el-button icon="el-icon-search" @click="getList(true)"></el-button>
        </template>
      </el-input>
    </div>
    <div class="list-wrap">
      <m-table
        :data="dataList"
        :loading="loading"
        :pagination="{
          total: prams.total,
          pageSize: prams.page_size,
          currentPage: prams.page,
        }"
        @currentChange="handleCurrentChange"
      >
        <template #empty>
          <el-empty description="暂无数据"></el-empty>
        </template>
        <el-table-column label="搜索图片">
          <template #default="goods">
            <div class="goods-wrap">
              <el-image
                style="width: 70px; height: 70px; border-radius: 4px"
                :src="goods.row.image"
                fit="cover"
              >
                <template #error>
                  <div class="image-slot">
                    <img src="@/assets/img/goodserro.png" alt="" />
                  </div>
                </template>
              </el-image>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="IP地址" prop="ip"></el-table-column>
        <el-table-column label="缓存类型">
          <template #default="cache">
            <el-tag v-if="cache.row.cache == 1">缓存</el-tag>
            <el-tag type="warning" v-else-if="cache.row.cache == 0">接口</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="客户端类型" prop="client_type"></el-table-column>
        <el-table-column label="搜索时间" prop="create_time"></el-table-column>
        <el-table-column label="搜索用户">
          <template #default="rows">
            <span v-if="rows.row.user_name">{{ rows.row.user_name }}</span>
            <span v-else>—</span>
          </template>
        </el-table-column>
      </m-table>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { goodsList } from '../api/SearchHistory';
import { parseTime } from '@/tools/index';
export default {
  name: 'SearchHistory',
  setup() {
    const prams = ref({
      page: 1,
      total: 0,
      page_size: 50,
      email: '',
      cache_type: '0',
      client_type: '',
      time_range: '',
      start_day: '',
      end_day: '',
    });
    const dataList = ref([]);
    const loading = ref(true);
    const getList = async (flag = false) => {
      if (flag == true) {
        prams.value.page = 1;
      }
      const { data } = await goodsList(
        {
          page: prams.value.page,
          page_size: prams.value.page_size,
          email: prams.value.email,
          cache_type: prams.value.cache_type,
          client_type: prams.value.client_type,
          start_day: prams.value.start_day,
          end_day: prams.value.end_day,
        },
        loading,
      );
      dataList.value = data.list;
      prams.value.total = Number(data.total);
    };
    const handleCurrentChange = val => {
      prams.value.page = val;
      getList();
    };
    const timeChange = val => {
      if (val == null) {
        prams.value.time_range = '';
      } else {
        prams.value.start_day = parseTime(new Date(val[0]).getTime(), '{y}-{m}-{d}');
        prams.value.end_day = parseTime(new Date(val[1]).getTime(), '{y}-{m}-{d}');
      }
    };
    return {
      prams,
      dataList,
      getList,
      handleCurrentChange,
      timeChange,
      loading,
    };
  },
  mounted() {
    this.getList();
  },
};
</script>

<style scoped lang="scss">
.view {
  padding: 15px;
  .serach_input {
    .el-select {
      width: 120px;
      margin: -10px 0 -10px 10px;
    }
  }
  .list-wrap {
    margin-top: 15px;
    .goods-wrap {
      max-width: 500px;
    }
  }
}
</style>
